import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Applying to FISH MED NET</h2>
      <p>
        The project FISH MED NET was designed and constructed with the intent of creating a positive environment of collaboration among fisher communities throughout the mediterranean. Under this initiativeFISH MED NET is open for any registered micro-to-small, medium enterprise who wish to participate in this project. This will benefit the enterprise by being listed and exposed on the E-platform. Therefore it will return visibility and direct contact with the public at a national and international level. 
      </p>
      <p>
        There is a simple rule for participating in this initiative and this is to adhere to the voluntary guidelines set in this project. These guidelines aim to respect the environment and socio-economic status they are involved in locally. They are in the Business Alliance Agreement (which is a non-binding agreement and no fees are required to participate) that will be delivered once an enterprise is ready to participate in this initiative.
      </p>
      <p>
        By adhering and respecting these voluntary rules FISH MED NET is aiming to create a better world!
      </p>

      <h2>How to apply?</h2>
      In order to lodge your interest please:
      <ul>
        <li>Contact your respective, or closest, counselling desk so your interest can be considered.</li>
        <li>We will then contact you and ask for proof of being a registered MSME in your country. </li>
        <li>Once all documents have been checked and are in order, we will provide you with: </li>
        <ul>
          <li>A form to fill out that will gather basic information related to your business activity only, and the</li>
          <li>Business Alliance Agreement that asks for the voluntary willingness of the enterprise to respect and adhere to the rules mentioned. This will need to be signed and sent back to the office.</li>
          <li>Once all documentation has been received by the competent office, the enterprise will be added soon after.</li>
        </ul>
      </ul>

      <h2>Our counseling desks</h2>
      <table>
        <tr>
          <th>Country</th><th>Region</th><th>Address</th><th>Working hours</th><th>Email</th>
        </tr>
        <tr>
          <td>France</td><td>Corsica</td><td>-</td><td>-</td><td><a href="mailto:FMNCorsicaFR@gmail.com">FMNCorsicaFR@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Liguria</td><td>-</td><td>-</td><td><a href="mailto:fmnliguria@gmail.com">fmnliguria@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Puglia</td><td>-</td><td>-</td><td><a href="mailto:fmnpugliait@gmail.com">fmnpugliait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Sardegna</td><td>-</td><td>-</td><td><a href="mailto:fmnsardegnait@gmail.com">fmnsardegnait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Tunisia</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNTunisieTN@gmail.com">FMNTunisieTN@gmail.com</a></td>
        </tr>
        <tr>
          <td>Lebanon</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNLebanonlb@gmail.com">FMNLebanonlb@gmail.com</a></td>
        </tr>
        <tr>
          <td>Palestine</td><td></td><td>-</td><td>-</td><td><a href="mailto:fmngazapal@gmail.com">fmngazapal@gmail.com</a></td>
        </tr>
      </table>

      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
